<template>
  <section class="pass-view" v-if="pass">
    <template
      v-if="
        pass.spacepassable_type === 'DECLARATION' && !isActive(pass.datetime_to)
      "
    >
      <div class="not-authorized">
        <svg-icon icon="x-circle"></svg-icon>
        <h3 class="not-authorized-title">
          {{ displayLabelName("pass", "pass", "not-authorized") }}
        </h3>
      </div>
    </template>
    <template v-else>
      <header class="pass-indicator">
        <div
          v-if="pass.declaration_status"
          class="pass-indicator-button"
          :class="{
            active: ['STARTED', 'CHECKED_IN', 'EXTENDED'].includes(
              pass.declaration_status.status
            ),
            warning: ['PRE_CHECK_IN', 'EXPIRING_WARNING'].includes(
              pass.declaration_status.status
            ),
            error: ['EXPIRING_WARNING'].includes(
              pass.declaration_status.status
            ),
          }"
        >
          <icon :icon="displayIcon(pass.declaration_status.status)"></icon>
        </div>
        <div
          v-else-if="pass.spacepassable_type === 'RESOURCE_ASSIGNMENT'"
          class="pass-indicator-button resource-assignment"
        >
          <icon icon="#cx-app1-favourite-default"></icon>
        </div>
      </header>
      <div class="pass-content">
        <section class="pass-desc">
          <h3 class="name">{{ pass.full_path[0] }}</h3>
          <template v-if="pass.full_path.length > 1">
            <p class="place">{{ pass.full_path.slice(1).join(", ") }}</p>
          </template>
        </section>
        <section class="pass-time">
          <div
            class="date"
            :class="{
              hidden:
                pass.spacepassable_type === 'RESOURCE_ASSIGNMENT' &&
                !pass.datetime_to,
            }"
          >
            <time :datetime="pass.datetime_from">
              {{
                displayDate(
                  pass.datetime_from,
                  globalDateFormat || "DD MMMM YYYY"
                )
              }}
            </time>
            <time
              v-if="
                pass.spacepassable_type === 'RESOURCE_ASSIGNMENT' &&
                  pass.datetime_to
              "
              :datetime="pass.datetime_to"
            >
              -
              {{
                displayDate(
                  pass.datetime_to,
                  globalDateFormat || "DD MMMM YYYY"
                )
              }}
            </time>
          </div>
          <div class="time" v-if="pass.spacepassable_type === 'DECLARATION'">
            <time :datetime="`${pass.datetime_from} - ${pass.datetime_to}`">
              {{
                displayDate(
                  pass.datetime_from_original,
                  globalTimeFormat || "HH:mm"
                )
              }}
              -
              {{
                displayDate(pass.datetime_to, globalTimeFormat || "HH:mm")
              }}</time
            >
            <time class="check-in-time" v-if="pass.pre_check_in">
              {{ displayLabelName("pass", "pass", "check-in") }}
              {{ displayDate(pass.pre_check_in, globalTimeFormat || "HH:mm") }}
              -
              {{
                displayDate(
                  pass.datetime_from_original,
                  globalTimeFormat || "HH:mm"
                )
              }}
            </time>
          </div>
        </section>
        <section class="qr-code">
          <img
            v-if="pass.qrcode"
            :src="pass.qrcode"
            width="175"
            height="175"
            alt="Qr code placeholder"
          />
          <icon v-else icon="#cx-app1-no-qrcode" />
        </section>
        <p class="pass-pin">{{ pass.pin }}</p>
        <div class="powered-by-clebex">
          <span class="text">Powered By</span>
          <img
            src="../../assets/images/main-nav-footer-logo.png"
            alt="Clebex logo"
          />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { displayDateWithTimezone, formatDate } from "@/services/helpers";
import dayjs from "dayjs";
import { mapGetters, mapState } from "vuex";

export default {
  name: "PassDetail",
  computed: {
    ...mapState("timezone", ["timezones"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
  },
  methods: {
    formatDate(date, format) {
      return formatDate(date, format);
    },
    isActive(expireTime) {
      return dayjs().isBefore(expireTime);
    },
    displayIcon(status) {
      let result;
      switch (status) {
        case "EXPIRING_WARNING":
          result = "#cx-app1-about-to-end-80x80";
          break;
        case "AUTO_RELEASED":
          result = "#cx-app1-auto-release-80x80";
          break;
        case "PRE_CHECK_IN":
          result = "#cx-app1-check-in-80x80";
          break;
        case "CHECKED_IN":
        case "STARTED":
          result = "#cx-app1-in-use-80x80";
          break;
        default:
          result = "#cx-app1-thumbs-up-80x80";
      }
      return result;
    },
    displayDate(date, format) {
      if (this.timezones && this.timezones.data) {
        return displayDateWithTimezone({
          date,
          format,
          timezones: this.timezones,
        });
      }
      return "";
    },
  },
  props: {
    pass: {
      required: true,
    },
  },
};
</script>
<style>
.pass-pin {
  color: #bf328d;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
</style>
